import React from "react";
import "./SignUp.css";
import logo from "../assets/EventTKTNG 1.png"; // Adjust the path as necessary
import appPromo from "../assets/Macscreen (2).png"; // Adjust the path as necessary
// import appPromoImage from "../assets//ScreenforSBM (1).png"; // Adjust the path as necessary
// import footerimg from "../assets/google-play-badge (1).png"; // Adjust the path as necessary

const Signup = () => {
  return (
    <div className="signup-container">
      <div className="left-section">
        <div className="content">
          <img src={logo} alt="" className="logoSp" />
          <img src={appPromo} alt="" className="promologo" />

          <h2>Print & Scan tickets offline with Ease.</h2>
          <p className="bottom-sec">
            Multi-user functionality with built-in ticket fraud detection
            ensures that you can manage your events effectively.
          </p>
        </div>
      </div>
      <div className="right-section">
        <p className="login-text">
          Already Registered? <a href="/">Log In now</a>
        </p>
        <div className="form-container">
          <div className="mid-section">
            <div className="topform">
              <h1>SIGN UP AND START PRINTING TICKETS</h1>
              <p>IT'S VERY SIMPLE TO GET STARTED</p>
            </div>
            <div className="card-container">
              <form>
                <div className="input-group">
                  <label className="label">Full Name</label>
                  <input type="text" placeholder="Full Name" required />
                </div>
                <div className="input-group">
                  <label className="label">Email</label>
                  <input type="email" placeholder="Email Address" required />
                </div>
                <div className="input-group">
                  <label className="label">Username</label>
                  <input type="text" placeholder="Username" required />
                </div>
                <div className="input-group">
                  <label className="label">Organization</label>
                  <input
                    type="text"
                    placeholder="Name of Organization"
                    required
                  />
                </div>
                <div className="input-group">
                  <label className="label">Password</label>
                  <input type="password" placeholder="Password" required />
                </div>
                <div className="input-group">
                  <label className="label">Confirm Password</label>
                  <input
                    type="password"
                    placeholder="Confirm Password"
                    required
                  />
                </div>
                <button type="submit" className="register-button">
                  REGISTER
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
        <div className="sponsor">
          <p>Powered By: Senior Barman Entertainment Ltd</p>
        </div>
    </div>
  );
};

export default Signup;
