import React, { useState } from 'react';
import { Modal, Button } from '@mui/material';
// import '../../App.css';

// Reusable modal component with optional action buttons
const ActionModal = ({ open, onClose, itemData }) => (
  <Modal open={open} onClose={onClose} className="modalStyle">
    <div className="modalContent">
      <h2>Details</h2>
      {itemData ? (
        <div>
          {Object.entries(itemData).map(([key, value]) => (
            <p key={key}>
              <strong>{key.charAt(0).toUpperCase() + key.slice(1)}:</strong> {value}
            </p>
          ))}
        </div>
      ) : (
        <p>No data available.</p>
      )}

      <div className="modalActions">
        <Button onClick={onClose}>Close</Button>
      </div>
    </div>
  </Modal>
);

const TableWithActions = ({ data, columns, actions = [], onAction }) => {
  const [open, setOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);

  const handleRowClick = (item) => {
    setCurrentItem(item);
    setOpen(true);
  };

  return (
    <div className="tableContainer">
      <table className="table">
        <thead>
          <tr>
            {columns.map((col) => (
              <th key={col.field}>{col.label}</th>
            ))}
            {/* Conditionally render the Actions header */}
            {actions.length > 0 && <th>Actions</th>}
          </tr>
        </thead>
        <tbody>
          {Array.isArray(data) && data.length > 0 ? (
            data.map((item) => (
              <tr key={item.id} onClick={() => handleRowClick(item)} style={{ cursor: 'pointer' }}>
                {columns.map((col) => (
                  <td key={col.field}>{item[col.field]}</td>
                ))}
                {/* Conditionally render the action buttons */}
                {actions.length > 0 && (
                  <td>
                    {actions.map((action) => (
                      <span
                        key={action.type}
                        onClick={(e) => {
                          e.stopPropagation(); // Prevents row click event
                          onAction(item, action.type);
                        }}
                        aria-label={action.label}
                        style={{ cursor: 'pointer', margin: '0 5px' }}
                      >
                        {action.icon}
                      </span>
                    ))}
                  </td>
                )}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={columns.length + (actions.length > 0 ? 1 : 0)}>No data available</td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Modal displaying item details */}
      <ActionModal
        open={open}
        onClose={() => setOpen(false)}
        itemData={currentItem}
      />
    </div>
  );
};

export default TableWithActions;















// import React, { useState } from "react";
// import { Modal, Button } from "@mui/material";
// import "../../App.css";

// // Reusable modal component with optional action buttons
// const ActionModal = ({ open, onClose, itemData }) => (
//   <Modal open={open} onClose={onClose} className="modalStyle">
//     <div className="modalContent">
//       <h2> Details</h2>
//       {itemData ? (
//         <div>
//           {Object.entries(itemData).map(([key, value]) => (
//             <p key={key}>
//               <strong>{key.charAt(0).toUpperCase() + key.slice(1)}:</strong>{" "}
//               {value}
//             </p>
//           ))}
//         </div>
//       ) : (
//         <p>No data available.</p>
//       )}

//       <div className="modalActions">
//         <Button onClick={onClose}>Close</Button>
//       </div>
//     </div>
//   </Modal>
// );

// const TableWithActions = ({ data, columns, actions = [], onAction }) => {
//   const [open, setOpen] = useState(false);
//   const [currentItem, setCurrentItem] = useState(null);

//   const handleRowClick = (item) => {
//     setCurrentItem(item);
//     setOpen(true);
//   };

//   return (
//     <div className="tableContainer">
//       <table className="table">
//         <thead>
//           <tr>
//             {columns.map((col) => (
//               <th key={col.field}>{col.label}</th>
//             ))}
//             {/* Conditionally render the Actions header */}
//             {actions.length > 0 && <th>Actions</th>}
//           </tr>
//         </thead>
//         <tbody>
//           {data.map((item) => (
//             <tr
//               key={item.id}
//               onClick={() => handleRowClick(item)}
//               style={{ cursor: "pointer" }}
//             >
//               {columns.map((col) => (
//                 <td key={col.field}>{item[col.field]}</td>
//               ))}
//               {/* Conditionally render the action buttons */}
//               {actions.length > 0 && (
//                 <td>
//                   {actions.map((action) => (
//                     <span
//                       key={action.type}
//                       onClick={(e) => {
//                         e.stopPropagation(); // Prevents row click event
//                         onAction(item, action.type);
//                       }}
//                       aria-label={action.label}
//                       style={{ cursor: "pointer", margin: "0 5px" }}
//                     >
//                       {action.icon}
//                     </span>
//                   ))}
//                 </td>
//               )}
//             </tr>
//           ))}
//         </tbody>
//       </table>

//       {/* Modal displaying item details */}
//       <ActionModal
//         open={open}
//         onClose={() => setOpen(false)}
//         itemData={currentItem}
//       />
//     </div>
//   );
// };

// export default TableWithActions;
