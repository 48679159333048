import React,{useState} from "react";
import "./Login.css";
import logo from "../assets/EventTKTNG 1.png"; // Adjust the path as necessary
import appPromo from "../assets/Macscreen (2).png"; // Adjust the path as necessary
import appPromoImage from "../assets//ScreenforSBM (1).png"; // Adjust the path as necessary
import footerimg from "../assets/google-play-badge (1).png"; // Adjust the path as necessary
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';  // Import useNavigate
import { login } from '../Redux/Slices/authSlice';

const Signin = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();  // Use useNavigate hook

  const handleLogin = () => {
    // Mock login validation
    if (username === 'Seniorbarman' && password === 'R0i7v5092^OBe') {
      dispatch(login({ username }));
      navigate('/client');  // Navigate to Admin Dashboard
    } else {
      alert('Invalid credentials');
    }
  };

  return (
    <div className="signin-container">
      <div className="left-sec">
        <div className="content">
          <img src={logo} alt="" className="logoSi" />
          <img src={appPromo} alt="" className="promologo" />
          <h2 className="sidetxt">
            Integrated seamlessly with our mobile App that scans your tickets at
            a lightning speed.
          </h2>
          <div className="app-promo">
            <img src={appPromoImage} alt="" className="app-image" />
            <a
              href="https://play.google.com/store"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={footerimg}
                alt="Get it on Google Play"
                className="google-play-badge"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="right-section">
        <div className="logo-container">
          <p className="signup-text">
            Don’t have an account yet?{" "}
            <Link to="/signup">Create your account here</Link>
            </p>
          {/* <img src="eventktng-logo.png" alt="eventktng.com" className="top-logo" /> */}
        </div>
        <div className="mid-section">
          <div className="form-container">
            <h1>WELCOME BACK</h1>
            <p>CONTINUE WITH YOUR USERNAME AND PASSWORD TO SIGN IN</p>
          </div>
          <div className="container">
            <form >
              <div className="input-group">
                <label className="lebel" htmlFor="username">Username</label>
                <input
                  type="text"
                  id="username"
                  placeholder="Username"
                  required
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
          
                />
              </div>
              <div className="input-group">
                <label  className="lebel" htmlFor="password">Password</label>
                <input
                  type="password"
                  id="password"
                  placeholder="Password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <button onClick={handleLogin} className="login-button">
                Login
              </button>
              <p className="forgot-password">
                <a href="/forgot-password">Forgot password?</a>
              </p>
            </form>
          </div>

          <div className="btsponsor">
            <p>Powered By: Senior Barman Entertainment Ltd</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signin;
