import React, { useState, useEffect } from "react";
import TableWithActions from "./TableWithActions"; // Import your reusable TableWithActions component
import Sidebar from "../Sidebar"; // Import Sidebar component
// import Modal from "@mui/material/Modal"; // Assuming you're using Material-UI for the modal
// import Box from "@mui/material/Box";
// import Typography from "@mui/material/Typography";

// Dummy data for users (in case you don't fetch from API)
// const dummyUsers = [
//   {
//     id: "1",
//     name: "John Doe",
//     email: "john.doe@example.com",
//     phone: "123-456-7890",
//     companyName: "Company A",
//   },
//   {
//     id: "2",
//     name: "Jane Smith",
//     email: "jane.smith@example.com",
//     phone: "987-654-3210",
//     companyName: "Company B",
//   },
// ];

const fetchAllPayments = async () => {
  try {
      const response = await fetch("http://localhost:3000/api/v1/payments", {
          method: "GET",
          headers: {
              "Content-Type": "application/json",
          },
      });

      if (!response.ok) {
          throw new Error(`Failed to fetch all tickets: ${response.statusText}`);
      }

      const ticketData = await response.json();
      console.log("All tickets fetched successfully:", ticketData);
      return ticketData;
  } catch (error) {
      console.error("Error fetching all tickets:", error);
      throw error;
  }
};

const Payment = () => {
 // State for users
 const [payment, setPayments] = useState('');
//  const [selectedUser, setSelectedUser] = useState(null); // State to store the selected user
//  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal open/close

 // Effect to fetch payment from an API (replace with your actual API)
 useEffect(() => {
  // Fetch tickets when the component mounts
  const fetchData = async () => {
    try {
      const result = await fetchAllPayments();
      setPayments(result.data || []); // handle missing or incorrect data gracefully
    } catch (error) {
      console.error("Error in useEffect while fetching ticket data:", error);
    }
  };

  fetchData(); // Call the async function inside useEffect
}, []); // Empty dependency array ensures the effect runs only once, when the component mounts


//  // Handle when a row is clicked (open modal with user details)
//  const handleRowClick = (user) => {
//    setSelectedUser(user); // Set the clicked user as selected
//    setIsModalOpen(true); // Open the modal
//  };

//  // Close the modal
//  const handleCloseModal = () => {
//    setIsModalOpen(false);
//    setSelectedUser(null); // Clear the selected user when modal is closed
//  };

//  const handleEdit = (user) => {
//    // Logic for editing user
//    console.log("Editing user:", user);
//  };

//  const handleDisable = (user) => {
//    const updatedUsers = payment.map((u) =>
//      u.id === user.id ? { ...u, disAble: !u.disAble } : u
//    );
//    setUsers(updatedUsers);
//    // Send request to update disable status
//    fetch(`/api/payment/${user.id}/disable`, { method: "PUT" });
//  };

//  const handleDelete = (user) => {
//    const updatedUsers = payment.filter((u) => u.id !== user.id);
//    setUsers(updatedUsers);
//    // Send request to delete user
//    fetch(`/api/payment/${user.id}`, { method: "DELETE" });
//  };

 return (
   <div className="clients-container">
     <section className="dashboard">
       <Sidebar />
     </section>

     {/* Table with actions */}
     <div  style={{margin:"120px"}}>
       <h1 style={{ marginBottom: "20px",marginLeft:"45px" }}>Clients</h1>

       {/* Pass data, columns, and actions to TableWithActions */}
       <TableWithActions
         data={payment}
         columns={[
           { field: "name", label: "Name" },
           { field: "email", label: "Email" },
           { field: "phone", label: "Phone" },
           { field: "companyName", label: "Company" },
         ]}
         actions={[]}
          onAction={() => {}}
          showActions={false}
       />
     </div>

     {/* Modal to display selected user details */}
     {/* <Modal
       open={isModalOpen}
       onClose={handleCloseModal}
       aria-labelledby="modal-modal-title"
       aria-describedby="modal-modal-description"
     >
       <Box
         sx={{
           position: "absolute",
           top: "50%",
           left: "50%",
           transform: "translate(-50%, -50%)",
           width: 400,
           bgcolor: "background.paper",
           boxShadow: 24,
           p: 4,
         }}
       >
         {selectedUser && (
           <>
             <Typography id="modal-modal-title" variant="h6" component="h2">
               {selectedUser.name}'s Details
             </Typography>
             <Typography id="modal-modal-description" sx={{ mt: 2 }}>
               <strong>Email:</strong> {selectedUser.email}
             </Typography>
             <Typography id="modal-modal-description" sx={{ mt: 1 }}>
               <strong>Phone:</strong> {selectedUser.phone}
             </Typography>
             <Typography id="modal-modal-description" sx={{ mt: 1 }}>
               <strong>Company:</strong> {selectedUser.companyName}
             </Typography>
             <Typography id="modal-modal-description" sx={{ mt: 1 }}>
               <strong>Verified:</strong>{" "}
               {selectedUser.isVerified ? "Yes" : "No"}
             </Typography>
             <Typography id="modal-modal-description" sx={{ mt: 1 }}>
               <strong>Disabled:</strong> {selectedUser.disAble ? "Yes" : "No"}
             </Typography>
           </>
         )}
       </Box>
     </Modal> */}
   </div>
 );
};

export default Payment;
