// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './Redux/storer';
import Login from '../src/components/Login';
// import Dashboard from '../src/components/Dashboard';
import SignUp from './components/SignUp';
import Tickets from '../src/components/screens/Tickets';
import Settings from '../src/components/screens/Settings';
import Payment from '../src/components/screens/Payment';
import Help from '../src/components/screens/Help';
import Clients from '../src/components/screens/Clients';
import PaymentFlutter from './components/utils/PaymentFlutter';
import "./App.css"; // The updated CSS file


const App = () => {
  console.log("App component rendered");

  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/client" element={<Clients />} />
          {/* <Route path="/dashboard" element={<Dashboard />} /> */}
          <Route path="/signup" element={<SignUp />} />
          <Route path="/ticket" element={<Tickets />} />
          <Route path="/setting" element={<Settings />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/help" element={<Help/>} />
          <Route path="/paymentflutter" element={<PaymentFlutter/>} />
        </Routes>
      </Router>
    </Provider>
  );
};

export default App;
