import React, { useState, useEffect } from "react";
import TableWithActions from "./TableWithActions"; // Import your reusable TableWithActions component
import Sidebar from "../Sidebar";
import Modal from "react-modal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import QRCode from "qrcode";
import "../../App.css"
// import "../Dashboard.css";
import Payment from "../utils/PaymentFlutter";
// import baseUrl from "../../const/baseUrl"

Modal.setAppElement("#root");

// const dummyUsers = [
//   {
//     id: "1",
//     clientname: "John Doe",
//     event: "NPL",
//     totalticket: 100,
//     date: "2024-08-29",
//     status: "upcoming",
//     price: "2500",
//   },

//   {
//     id: "2",
//     clientname: "Jane Smith",
//     event: "Concert",
//     totalticket: 200,
//     date: "2024-09-01",
//     status: "expired",
//     price: "3500",
//   },
//   {
//     id: "3",
//     clientname: "Jane Smith",
//     event: "Concert",
//     totalticket: 200,
//     date: "2024-09-01",
//     status: "expired",
//     price: "3500",
//   },
// ];
    // Utility function for fetching ticket data from the backend
   
    const fetchAllTickets = async () => {
      try {
          const response = await fetch("http://localhost:3000/api/v1/tickets/all", {
              method: "GET",
              headers: {
                  "Content-Type": "application/json",
              },
          });
  
          if (!response.ok) {
              throw new Error(`Failed to fetch all tickets: ${response.statusText}`);
          }
  
          const ticketData = await response.json();
          console.log("All tickets fetched successfully:", ticketData);
          return ticketData;
      } catch (error) {
          console.error("Error fetching all tickets:", error);
          throw error;
      }
  };
  
    
    

const Tickets = () => {
  // const [tickets, setUsers] = useState(dummyUsers);
  const [tickets, setTickets] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalOneIsOpen, setModalOneIsOpen] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [eventTime, setEventTime] = useState("");
  const [ticketPrice, setTicketPrice] = useState("");
  const [ticketQuantity, setTicketQuantity] = useState(500);
  const [firstTeamName, setFirstTeamName] = useState("");
  const [secondTeamName, setSecondTeamName] = useState("");
  const [event, setEvent] = useState("");
  const [location, setLocation] = useState("");
  const openModal = () => setModalIsOpen(true);
  const openModalOne = () => setModalOneIsOpen(true);
  // const secretKey = process.env.REACT_APP_SECRET_KEY;
  // const baseUrl = process.env.REACT_APP_BASEURL;

  const closeModal = () => {
    setModalIsOpen(false);
    clearModalFields(); // Clear modal fields when closing
  };

  const closeModalEvent = () => {
    setModalOneIsOpen(false);
    clearModalFieldsTop(); // Clear modal fields when closing
  };

  const readFileAsDataURL = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  // Utility function for reading files as DataURLs
  const readImages = async (fileInputs, maxWidth, maxHeight) => {
    return Promise.all(
      fileInputs.map((input) =>
        input.files.length > 0
          ? readFileAsDataURL(input.files[0], maxWidth, maxHeight)
          : null
      )
    );
  };

  // Refactored handleSave function
  const handleSave = async (e) => {
    e.preventDefault();
    try {
      const qrCodes = [];
      const fileInputs = [
        document.getElementById("centerImage"),
        document.getElementById("logoImage1"),
        document.getElementById("logoImage2"),
        document.getElementById("logoImage3"),
        document.getElementById("logoImage4"),
      ];
      const maxWidth = 50;
      const maxHeight = 50;
      const images = (await readImages(fileInputs, maxWidth, maxHeight)) || [];

      // Generate QR codes
      const ticketNumbers = [];
      for (let i = 0; i < ticketQuantity; i++) {
        const qrCodeData = JSON.stringify({ event, ticketNumber: i });
        const qrCode = await QRCode.toDataURL(qrCodeData);
        qrCodes.push(qrCode);
        ticketNumbers.push(i);
      }

      // await calculateCharge(ticketQuantity, ticketPrice);
      // await sendTicketData(event, endDate, ticketNumbers);

      const truncateTeamName = (name, length) =>
        name?.substring(0, length) || "";

      const truncatedFirstTeamName = truncateTeamName(firstTeamName, 7);
      const truncatedSecondTeamName = truncateTeamName(secondTeamName, 7);
      const simpleData = {
        qrCodes,
        images,
        event,
        ticketPrice,
        firstTeamName: truncatedFirstTeamName,
        secondTeamName: truncatedSecondTeamName,
        startDate,
        endDate,
        eventTime,
        filename: `${event}.pdf`,
        ticketNumbers,
        ticketQuantity
    };
    
      Payment(simpleData);

      closeModal();
    } catch (error) {
      console.error("Error generating tickets:", error);
    }
  };

  // Refactored handleSaveEvent function
  const handleSaveEvent = async (e) => {
    e.preventDefault();
    try {
      const qrCodes = [];
      const fileInputs = [
        document.getElementById("centerImage"),
        document.getElementById("logoImage3"),
        document.getElementById("logoImage4"),
      ];
      const maxWidth = 50;
      const maxHeight = 50;
      const images = (await readImages(fileInputs, maxWidth, maxHeight)) || [];

      // Generate QR codes
      const ticketNumbers = [];
      for (let i = 0; i < ticketQuantity; i++) {
        const qrCodeData = JSON.stringify({ event, ticketNumber: i });
        const qrCode = await QRCode.toDataURL(qrCodeData);
        qrCodes.push(qrCode);
        ticketNumbers.push(i);
      }

      // await calculateCharge(ticketQuantity, ticketPrice);
      // await sendTicketData(event, endDate, ticketNumbers);

      const truncateEventName = (name, length) =>
        name?.substring(0, length) || "";

      const eventName = truncateEventName(event, 15);
      const locationName = truncateEventName(location, 15);
      const simpleData = {
        qrCodes, 
        images,
          event: eventName,
          location: locationName,
          ticketPrice,
          startDate,
          endDate,
          eventTime,
          filename: `${event}.pdf`,
          ticketNumbers,
          ticketQuantity
    };
      Payment(simpleData);

      closeModalEvent();
    } catch (error) {
      console.error("Error generating event tickets:", error);
    }
  };

  const clearModalFields = () => {
    setStartDate("");
    setEndDate("");
    setEventTime("");
    setTicketPrice("");
    setTicketQuantity(500);
    setFirstTeamName("");
    setSecondTeamName("");
    setEvent("");
  };

  const clearModalFieldsTop = () => {
    setStartDate("");
    setEndDate("");
    setEventTime("");
    setTicketPrice("");
    setTicketQuantity(500);
    setEvent("");
    setLocation("");
    setEvent("");
  };


useEffect(() => {
  // Fetch tickets when the component mounts
  const fetchData = async () => {
    try {
      const result = await fetchAllTickets();
      setTickets(result.data || []); // handle missing or incorrect data gracefully
    } catch (error) {
      console.error("Error in useEffect while fetching ticket data:", error);
    }
  };

  fetchData(); // Call the async function inside useEffect
}, []); // Empty dependency array ensures the effect runs only once, when the component mounts


  return (
    <div className="clients-container">
      <section className="dashboard">
        <Sidebar />
      </section>

      <div className="dasboard">
      {/* Buttons for adding tickets and generating an event */}
        <button onClick={openModal} style={buttonStyle}>
          MatchTicket
        </button>
        <button
          onClick={openModalOne}
          style={{ ...buttonStyle, marginLeft: "10px" }}
        >
          EventTicket
        </button>
      </div>
      
      <div
        style={{
          justifyContent: "center",
          alignSelf: "center",
          marginLeft: "-999px",
          marginTop: "-265px",
          marginRight: "30px",
        }}
      >
        <h1 style={{ marginBottom: "20px", marginLeft: "125px" }}>Tickets</h1>

        <TableWithActions
          data={tickets}
          columns={[
            { field: "clientname", label: "Client Name" },
            { field: "event", label: "Event" },
            { field: "totalticket", label: "TotalTickets" },
            {
              field: "status",
              label: "Status",
              render: (rowData) => {
                const statusStyle = {
                  width: "75px",
                  height: "29px",
                  padding: "4px 12px",
                  borderRadius: "4px 0 0 0",
                  border: "1px solid transparent",
                  backgroundColor:
                    rowData.status === "upcoming" ? "green" : "red",
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                };

                return <div style={statusStyle}>{rowData.status}</div>;
              },
            },
            { field: "price", label: "Price" },
            { field: "date", label: "Date" },
          ]}
          actions={[]}
          onAction={() => {}}
          showActions={false}
        />
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="modal"
        overlayClassName="modal-overlay"
      >
        <h2>Create New Ticket</h2>
        <form>
          <input
            type="text"
            placeholder="Event Name"
            value={event}
            onChange={(e) => setEvent(e.target.value)}
          />
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            placeholderText="Start date"
          />
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            placeholderText="Date"
          />
          <input
            type="time"
            value={eventTime}
            onChange={(e) => setEventTime(e.target.value)}
          />
          <input
            type="number"
            placeholder="Ticket Price"
            value={ticketPrice}
            onChange={(e) => setTicketPrice(e.target.value)}
          />
          <input
            type="number"
            placeholder="Ticket Quantity"
            value={ticketQuantity}
            onChange={(e) => setTicketQuantity(e.target.value)}
          />
          <input
            type="text"
            placeholder="First Team Name"
            value={firstTeamName}
            onChange={(e) => setFirstTeamName(e.target.value)}
          />
          <input
            type="text"
            placeholder="Second Team Name"
            value={secondTeamName}
            onChange={(e) => setSecondTeamName(e.target.value)}
          />
          <label>
            TeamLogo1: <input type="file" id="logoImage1" />
          </label>
          <label>
            TeamLogo2: <input type="file" id="logoImage2" />
          </label>
          <label>
            Sponsor1: <input type="file" id="centerImage" />
          </label>
          <label>
            Sponsor2: <input type="file" id="logoImage3" />
          </label>
          <label>
            Sponsor3: <input type="file" id="logoImage4" />
          </label>
          <button type="button" onClick={handleSave}>
            Print Ticket
          </button>
        </form>
      </Modal>
      <Modal
        isOpen={modalOneIsOpen}
        onRequestClose={closeModalEvent}
        className="modal"
        overlayClassName="modal-overlay"
      >
        <h2>Create Event Ticket</h2>
        <form>
          <input
            type="text"
            placeholder="Event Name"
            value={event}
            onChange={(e) => setEvent(e.target.value)}
          />
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            placeholderText="Start date"
          />
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            placeholderText="End Date"
          />
          <input
            type="time"
            value={eventTime}
            onChange={(e) => setEventTime(e.target.value)}
          />
          <input
            type="number"
            placeholder="Ticket Price"
            value={ticketPrice}
            onChange={(e) => setTicketPrice(e.target.value)}
          />
          <input
            type="number"
            placeholder="Ticket Quantity"
            value={ticketQuantity}
            onChange={(e) => setTicketQuantity(e.target.value)}
          />
          <input
            type="text"
            placeholder="location"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
          />
          <label>
            Sponsor1: <input type="file" id="centerImage" />
          </label>
          <label>
            Sponsor2: <input type="file" id="logoImage3" />
          </label>
          <label>
            Sponsor3: <input type="file" id="logoImage4" />
          </label>
          <button type="button" onClick={handleSaveEvent}>
            Print
          </button>
        </form>
      </Modal>
    </div>
  );
};

const buttonStyle = {
  height: "35px",
  padding: "6px 6px", // Reduced padding
  fontSize: "14px", // Smaller font size
  backgroundColor: "#FF6A00",
  color: "white",
  border: "none",
  cursor: "pointer",
  borderRadius: "4px",
  marginTop: "95px",
  marginLeft: "995px",
};

export default Tickets;
