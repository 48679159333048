// redux/store.js
import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../Redux/Slices/authSlice';
import scanReducer from '../Redux/Slices/scanSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    scan: scanReducer,
  },
});



