import jsPDF from "jspdf";

const generateDynamicPDF = (optionsData) => {
    const {
        qrCodes,
        images,
        event, 
        ticketPrice,
        firstTeamName, 
        secondTeamName,
        location,
        startDate,
        endDate,
        eventTime,
        filename = `${event}.pdf`, // Default filename if not provided
    } = optionsData;


    const doc = new jsPDF({ orientation: "landscape" });
    const ticketsPerPage = 9;
    const ticketWidth = 92;
    const ticketHeight = 38;
    const margin = 5;

    qrCodes.forEach((qrCode, index) => {
      const columnIndex = index % 3;
      const rowIndex = Math.floor(index / 3) % 3;
      const xPosition = columnIndex * (ticketWidth + margin) + margin;
      const yPosition = rowIndex * (ticketHeight + margin) + margin;

      if (index % ticketsPerPage === 0 && index !== 0) {
        doc.addPage();
      }

      doc.roundedRect(xPosition, yPosition, ticketWidth, ticketHeight, 3, 3);

      // Set QR code position and size
      const qrXPosition = xPosition + ticketWidth - 27;
      const qrYPosition = yPosition + 6;
      const qrWidth = 20;
      const qrHeight = 20;

      if (typeof qrCode === "string") {
        doc.addImage(
          qrCode,
          "PNG",
          qrXPosition,
          qrYPosition,
          qrWidth,
          qrHeight
        );
      } else if (qrCode.qrCodeData) {
        doc.addImage(
          qrCode.qrCodeData,
          "PNG",
          qrXPosition,
          qrYPosition,
          qrWidth,
          qrHeight
        );
      }

      // Set details position (aligned to the left of the ticket)
      const detailsX = xPosition + 5;
      let detailsY = yPosition + 10;

      doc.setFontSize(15);
      doc.setFont("helvetica", "bold");
      const priceX = detailsX + 5;
      const priceY = yPosition + 25;

      // Add rotated price text
      doc.text(`${ticketPrice}`, priceX, priceY - 4, { angle: +90 });
      doc.text(`N`, priceX - 3, priceY - 2, { angle: -90 });

      // Add event name
      doc.setFontSize(11);
      doc.setFont("helvetica", "normal");
      doc.text(`${event}`, detailsX + 32, detailsY - 5);

      // Add team names with 'vs' in between
      doc.setFontSize(10);
      doc.setFont("helvetica", "bold");

      if (firstTeamName && secondTeamName) {
        // Add firstTeamName above
        doc.text(firstTeamName, detailsX + 26, detailsY + 2);

        // Add "vs" below firstTeamName
        doc.text("vs", detailsX + 24, detailsY + 5);

        // Add secondTeamName below "vs"
        doc.text(secondTeamName, detailsX + 19, detailsY + 10);
      } else {
        // Fallback to event at location if no team names
        const detailsText = `${event} at ${location}`;
        doc.text(detailsText, detailsX + 15, detailsY + 5);
      }

      // Add event date and time
      const startdateText = `${startDate?.toDateString()}, ${eventTime}`;
      const endDateText = `${endDate?.toDateString()},${eventTime}`;
      doc.setFontSize(9);
      doc.text(startdateText, detailsX + 15, detailsY + 14);
      doc.text(endDateText, detailsX + 15, detailsY + 17);

      // Function to draw a circular image
      const drawCircularImage = (imgData, x, y, size) => {
        doc.saveGraphicsState();
        doc.clip();
        doc.circle(x + size / 2, y + size / 2, size / 2);
        doc.addImage(imgData, "PNG", x, y, size, size);
        doc.restoreGraphicsState();
      };

      // Check the number of images
      const totalImages = images.length;

      if (totalImages === 5) {
        // Position first image left of firstTeamName, second right of secondTeamName, remaining under date/time
        const firstImgX = detailsX - 20;
        const firstImgY = detailsY - 10;
        const secondImgX = detailsX + 60;
        const secondImgY = detailsY - 10;

        // Draw first image left of firstTeamName
        drawCircularImage(images[0], firstImgX + 31, firstImgY + 11, 7);
        // Draw second image right of secondTeamName
        drawCircularImage(images[1], secondImgX - 11, secondImgY + 11, 7);

        // Remaining images under date/time
        for (let i = 2; i < totalImages; i++) {
          const imgX = detailsX + (i - 2) * 20; // Positioned under the date
          const imgY = detailsY + 35; // Positioned below date/time
          doc.addImage(images[i], "PNG", imgX + 12, imgY - 15, 16, 6);
        }
      } else if (totalImages <= 4) {
        // Position the first image on the right side of event/location in a circle
        const firstImgX = detailsX + 40;
        const firstImgY = detailsY - 5;
        drawCircularImage(images[0], firstImgX + 5, firstImgY + 6, 7);

        // Position remaining images under date/time
        for (let i = 1; i < totalImages; i++) {
          const imgX = detailsX + (i - 1) * 20; // Positioned under the date
          const imgY = detailsY + 35; // Positioned below date/time
          doc.addImage(images[i], "PNG", imgX + 12, imgY - 17, 18, 7);
        }
      }
    });

    // Save the generated PDF to the given filename
    doc.save(filename);
    console.log("Tickets generated successfully!");
  };

export default generateDynamicPDF