import React, { useState, useEffect } from "react";
import "../App.css";

import { FaUserAlt, FaTicketAlt, FaCreditCard, FaQuestionCircle } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import logo from "../assets/barlogo.png";
import Header from "./screens/Header";

function Sidebar() {
  const [userName, setUserName] = useState("");

  useEffect(() => {
    const storedUserName = localStorage.getItem("userName");
    if (storedUserName) {
      setUserName(storedUserName);
    }
  }, []);

  useEffect(() => {
    window.onpopstate = function () {
      window.history.go(1);
    };

    return () => {
      window.onpopstate = null;
    };
  }, []);

  return (
    <div>
      <Header userName={userName} />

      <div className="side" style={{ width: "199px" }}> {/* Fixed width */}
        <div className="bar">
          <div className="logo-div">
            <h1 style={{ cursor: "pointer" }}>
              <img src={logo} alt="Logo" className="logo" />
            </h1>
          </div>
        </div>

        {/* Sidebar navigation links with icons */}
        <NavLink to="/client" className="nav-link" activeClassName="active">
          <FaUserAlt className="icon" />
          <span className="nav-text">Client</span>
        </NavLink>

        <NavLink to="/ticket" className="nav-link" activeClassName="active">
          <FaTicketAlt className="icon" />
          <span className="nav-text">Tickets</span>
        </NavLink>

        <NavLink to="/payment" className="nav-link" activeClassName="active">
          <FaCreditCard className="icon" />
          <span className="nav-text">Payment</span>
        </NavLink>

        {/* <NavLink to="/setting" className="nav-link" activeClassName="active">
          <FaCog className="icon" />
          <span className="nav-text">Settings</span>
        </NavLink> */}

        <NavLink to="/help" className="nav-link" activeClassName="active">
          <FaQuestionCircle className="icon" />
          <span className="nav-text">Help</span>
        </NavLink>
      </div>
    </div>
  );
}

export default Sidebar;












// import React, { useState, useEffect } from "react";
// import "../App.css";
// import "../components/Dashboard.css";

// import { FaBars, FaUserAlt, FaTicketAlt, FaCreditCard, FaCog, FaQuestionCircle } from "react-icons/fa"; // Importing icons
// import { IoCloseSharp } from "react-icons/io5";
// import { NavLink } from "react-router-dom";
// import logo from "../assets/barlogo.png"; // Adjust the path as necessary
// import Header from "./screens/Header";

// function Sidebar() {
//   const [isDropdownOpen, setDropdownOpen] = useState(false);
//   const [userName, setUserName] = useState("");

//   useEffect(() => {
//     // Retrieve the logged-in user's name from localStorage (or any global state)
//     const storedUserName = localStorage.getItem("userName"); // Example: 'John Doe'
//     if (storedUserName) {
//       setUserName(storedUserName);
//     }
//   }, []);

//   const [isOpen, setIsOpen] = useState(false);
//   const toggle = () => setIsOpen(!isOpen);

//   const [open1, setOpen1] = useState(false);

//   const handleClick = () => {
//     setOpen1(!open1);
//     setDropdownOpen(!isDropdownOpen);
//     document.body.style.overflow = open1 ? "auto" : "hidden"; // Disable or enable scrolling
//   };

//   useEffect(() => {
//     // Handle the case where user presses back button on home screen
//     window.onpopstate = function () {
//       window.history.go(1);
//     };

//     return () => {
//       // Clean up listener on component unmount
//       window.onpopstate = null;
//     };
//   }, []);

//   return (
//     <div>
//       <Header userName={userName} />
//       <div className="media-bar" onClick={handleClick}>
//         {open1 ? (
//           <IoCloseSharp id="close" style={{ color: "#fff" }} />
//         ) : (
//           <FaBars id="bar" style={{ color: "#fff" }} />
//         )}
//       </div>

//       <div className="side" style={{ width: isOpen ? "155px" : "250px" }}>
//         <div className="bar">
//           <div className="logo-div">
//             <h1 onClick={toggle} style={{ cursor: "pointer" }}>
//               <img
//                 src={logo}
//                 alt="Logo"
//                 className={isOpen ? "logo-expanded" : "logo"}
//               />
//             </h1>
//           </div>
//         </div>

//         {/* Sidebar navigation links with icons */}
//         <NavLink to="/client" className="nav-link" activeClassName="active">
//           <FaUserAlt className="icon" /> {/* User icon */}
//           <span className="nav-text">Client</span>
//         </NavLink>

//         <NavLink to="/ticket" className="nav-link" activeClassName="active">
//           <FaTicketAlt className="icon" /> {/* Ticket icon */}
//           <span className="nav-text">Tickets</span>
//         </NavLink>

//         <NavLink to="/payment" className="nav-link" activeClassName="active">
//           <FaCreditCard className="icon" /> {/* Payment icon */}
//           <span className="nav-text">Payment</span>
//         </NavLink>

//         <NavLink to="/setting" className="nav-link" activeClassName="active">
//           <FaCog className="icon" /> {/* Settings icon */}
//           <span className="nav-text">Settings</span>
//         </NavLink>

//         <NavLink to="/help" className="nav-link" activeClassName="active">
//           <FaQuestionCircle className="icon" /> {/* Help icon */}
//           <span className="nav-text">Help</span>
//         </NavLink>
//       </div>
//     </div>
//   );
// }

// export default Sidebar;













// import React, { useState, useEffect } from "react";
// import "../App.css";

// import { FaUserAlt, FaTicketAlt, FaCreditCard, FaCog, FaQuestionCircle } from "react-icons/fa";
// import { NavLink } from "react-router-dom";
// import logo from "../assets/barlogo.png";
// import Header from "./screens/Header";

// function Sidebar() {
//   const [userName, setUserName] = useState("");

//   useEffect(() => {
//     const storedUserName = localStorage.getItem("userName");
//     if (storedUserName) {
//       setUserName(storedUserName);
//     }
//   }, []);

//   useEffect(() => {
//     window.onpopstate = function () {
//       window.history.go(1);
//     };

//     return () => {
//       window.onpopstate = null;
//     };
//   }, []);

//   return (
//     <div>
//       <Header userName={userName} />

//       <div className="side" style={{ width: "199px" }}> {/* Fixed width */}
//         <div className="bar">
//           <div className="logo-div">
//             <h1 style={{ cursor: "pointer" }}>
//               <img src={logo} alt="Logo" className="logo" />
//             </h1>
//           </div>
//         </div>

//         {/* Sidebar navigation links with icons */}
//         <NavLink to="/client" className="nav-link" activeClassName="active">
//           <FaUserAlt className="icon" />
//           <span className="nav-text">Client</span>
//         </NavLink>

//         <NavLink to="/ticket" className="nav-link" activeClassName="active">
//           <FaTicketAlt className="icon" />
//           <span className="nav-text">Tickets</span>
//         </NavLink>

//         <NavLink to="/payment" className="nav-link" activeClassName="active">
//           <FaCreditCard className="icon" />
//           <span className="nav-text">Payment</span>
//         </NavLink>

//         <NavLink to="/setting" className="nav-link" activeClassName="active">
//           <FaCog className="icon" />
//           <span className="nav-text">Settings</span>
//         </NavLink>

//         <NavLink to="/help" className="nav-link" activeClassName="active">
//           <FaQuestionCircle className="icon" />
//           <span className="nav-text">Help</span>
//         </NavLink>
//       </div>
//     </div>
//   );
// }

// export default Sidebar;












// // import React, { useState, useEffect } from "react";
// // import "../App.css";
// // import "../components/Dashboard.css";

// // import { FaBars, FaUserAlt, FaTicketAlt, FaCreditCard, FaCog, FaQuestionCircle } from "react-icons/fa"; // Importing icons
// // import { IoCloseSharp } from "react-icons/io5";
// // import { NavLink } from "react-router-dom";
// // import logo from "../assets/barlogo.png"; // Adjust the path as necessary
// // import Header from "./screens/Header";

// // function Sidebar() {
// //   const [isDropdownOpen, setDropdownOpen] = useState(false);
// //   const [userName, setUserName] = useState("");

// //   useEffect(() => {
// //     // Retrieve the logged-in user's name from localStorage (or any global state)
// //     const storedUserName = localStorage.getItem("userName"); // Example: 'John Doe'
// //     if (storedUserName) {
// //       setUserName(storedUserName);
// //     }
// //   }, []);

// //   const [isOpen, setIsOpen] = useState(false);
// //   const toggle = () => setIsOpen(!isOpen);

// //   const [open1, setOpen1] = useState(false);

// //   const handleClick = () => {
// //     setOpen1(!open1);
// //     setDropdownOpen(!isDropdownOpen);
// //     document.body.style.overflow = open1 ? "auto" : "hidden"; // Disable or enable scrolling
// //   };

// //   useEffect(() => {
// //     // Handle the case where user presses back button on home screen
// //     window.onpopstate = function () {
// //       window.history.go(1);
// //     };

// //     return () => {
// //       // Clean up listener on component unmount
// //       window.onpopstate = null;
// //     };
// //   }, []);

// //   return (
// //     <div>
// //       <Header userName={userName} />
// //       <div className="media-bar" onClick={handleClick}>
// //         {open1 ? (
// //           <IoCloseSharp id="close" style={{ color: "#fff" }} />
// //         ) : (
// //           <FaBars id="bar" style={{ color: "#fff" }} />
// //         )}
// //       </div>

// //       <div className="side" style={{ width: isOpen ? "155px" : "250px" }}>
// //         <div className="bar">
// //           <div className="logo-div">
// //             <h1 onClick={toggle} style={{ cursor: "pointer" }}>
// //               <img
// //                 src={logo}
// //                 alt="Logo"
// //                 className={isOpen ? "logo-expanded" : "logo"}
// //               />
// //             </h1>
// //           </div>
// //         </div>

// //         {/* Sidebar navigation links with icons */}
// //         <NavLink to="/client" className="nav-link" activeClassName="active">
// //           <FaUserAlt className="icon" /> {/* User icon */}
// //           <span className="nav-text">Client</span>
// //         </NavLink>

// //         <NavLink to="/ticket" className="nav-link" activeClassName="active">
// //           <FaTicketAlt className="icon" /> {/* Ticket icon */}
// //           <span className="nav-text">Tickets</span>
// //         </NavLink>

// //         <NavLink to="/payment" className="nav-link" activeClassName="active">
// //           <FaCreditCard className="icon" /> {/* Payment icon */}
// //           <span className="nav-text">Payment</span>
// //         </NavLink>

// //         <NavLink to="/setting" className="nav-link" activeClassName="active">
// //           <FaCog className="icon" /> {/* Settings icon */}
// //           <span className="nav-text">Settings</span>
// //         </NavLink>

// //         <NavLink to="/help" className="nav-link" activeClassName="active">
// //           <FaQuestionCircle className="icon" /> {/* Help icon */}
// //           <span className="nav-text">Help</span>
// //         </NavLink>
// //       </div>
// //     </div>
// //   );
// // }

// // export default Sidebar;
