import React,{useEffect} from "react";
import { FlutterWaveButton, closePaymentModal } from "flutterwave-react-v3";
import axios from 'axios';
import baseUrl from "../../const/baseUrl"
import generateDynamicPDF from "../utils/generateDynamicPDF";

import logocomp from "../../assets/barlogo.png"
const PaymentFlutter = (simpleData) => {

    useEffect(() => {
        const genData = {
            qrCodes: simpleData.qrCodes,
            images: simpleData.images,
            event: simpleData.event, 
            ticketPrice: simpleData.amount,
            firstTeamName: simpleData.firstTeamName, 
            secondTeamName: simpleData.secondTeamName,
            startDate: simpleData.startDate,
            endDate: simpleData.endDate,
            eventTime: simpleData.eventTime,
            filename: `${simpleData.event}.pdf`, // Corrected filename
        };

        const ticketData = {
            event: simpleData.event, 
            endDate: simpleData.endDate,
            ticketNumbers: simpleData.ticketNumbers,
        };
        generateDynamicPDF(genData);
        sendTicketData(ticketData);
    }, [simpleData]); // Only re-run if simpleData changes
    

    const saveTransaction = async (resData) => {
        try {
          const response = await fetch(`${baseUrl}/payments/`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(resData),
          });
    
          if (!response.ok) {
            throw new Error(`Failed to send ticket data: ${response.statusText}`);
          }
    
          console.log("Ticket data sent successfully.");
        } catch (error) {
          console.error("Error sending ticket data:", error);
          throw error; // rethrow the error so it can be caught by the caller
        }
      };

      // Utility function for sending ticket data to the backend
  const sendTicketData = async (ticketData) => {
    try {
      const response = await fetch(`${baseUrl}/tickets/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
            event:ticketData.event,
             endDate:ticketData.endDate?.toISOString(), 
             ticketNumbers:ticketData.ticketNumbers
        }),
      });

      if (!response.ok) {
        throw new Error(`Failed to send ticket data: ${response.statusText}`);
      }

      console.log("Ticket data sent successfully.");
    } catch (error) {
      console.error("Error sending ticket data:", error);
      throw error; // rethrow the error so it can be caught by the caller
    }
  };


// Function to verify the transaction using Flutterwave's API
const verifyTransaction = async (tx_ref) => {
    try {
        const response = await axios.get(`https://api.flutterwave.com/v3/transactions/${tx_ref}/verify`, {
            headers: {
                Authorization: `Bearer ${process.env.REACT_APP_FLUTTERWAVE_SECRET_KEY}`,
            },
        });

        if (response.data.status === 'success') {
            const resData = response.data.data; // Transaction data
            console.log('Transaction verification successful:', resData);
            // Save transaction details after successful verification
            saveTransaction(resData);
        } else {
            console.log('Transaction verification failed:', response.data);
        }
    } catch (error) {
        console.error('Error verifying transaction:', error);
    }
};


const ticketCharge = (ticketQuantity, amount) => {
    let rate;
    if (ticketQuantity < 1000) {
      rate = 0.15;
    } else if (ticketQuantity >= 1000 && ticketQuantity < 10000) {
      rate = 0.1;
    } else if (ticketQuantity >= 10000) {
      rate = 0.05;
    } else {
      rate = 0;
    }
    // Calculate the total amount based on the number of tickets
    const totalAmount = ticketQuantity * amount;
    // Calculate the charge based on the total amount and the rate
    const totalCharge = totalAmount * rate;
    return totalCharge;
  };

  // Compute the total charge based on ticketQuantity and amount
  const totalCharge = ticketCharge(simpleData.ticketQuantity, simpleData.amount);



  const config = {
    public_key: process.env.REACT_APP_FLUTTERWAVE_PUBLIC_KEY,
    tx_ref: Date.now(), // A unique reference for the transaction
    amount: totalCharge + 100, // Adjust this amount as needed
    currency: 'USD', // Set currency
    payment_options: 'card,mobilemoney,ussd',
    customer: {
        email: 'customer@example.com', // Use dynamic email
        phonenumber: '080****', // Use dynamic phone number
        name: 'Customer Name', // Use dynamic customer name
    },
    customizations: {
        title: 'Ticket Payment',
        description: 'Payment for generated ticket',
        logo: logocomp, // Optional logo URL
    },
};


const fwConfig = {
    ...config,
    text: 'Pay Now',
    callback: (response) => {
        console.log('Payment callback response:', response);
        // Close the payment modal
        closePaymentModal();
        // Verify the transaction using the transaction reference (tx_ref)
        verifyTransaction(response.tx_ref);
    },
    onClose: () => {
        console.log('Payment modal closed');
    },
};

return <FlutterWaveButton {...fwConfig} />;
};

export default PaymentFlutter;
