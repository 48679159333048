import React from 'react';
// import '../../App.css'
const Header = ({ userName }) => {
  return (
    <header >
      <h1 className='header-sty'>{userName ? `${userName}'s Dashboard` : 'Dashboard'}</h1>
      <div className="profile-icon">👤</div>
    </header>
  );
};

export default Header;
